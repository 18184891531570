import React from 'react';
import Helmet from 'react-helmet';

import Logo from '../components/logo/logo';

import * as styles from './landing-page.module.scss';

export default function LandingPage() {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Timeless Homes"
      >
        <meta name="description" content="New website coming soon." />
      </Helmet>
      <main className={styles.landingPage}>
        <Logo className={styles.logo} />
        <p>
          For more information about Timeless Homes and our developments call{' '}
          <span>Ashley</span> on <a href="tel:+447713036117">07713 036117</a> or{' '}
          <a href="mailto:ashley@timelessbs.co.uk">email here</a>
        </p>
      </main>
    </>
  );
}
